const PlaneSvg = (
  <svg
    width='20'
    height='18'
    viewBox='0 0 20 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.9427 2.13516C20.2107 0.884258 19.4959 0.39283 18.6918 0.705557L0.911028 7.54086C-0.295203 8.03229 -0.250528 8.70242 0.732327 9.01515L5.24452 10.4001L15.7879 3.78815C16.2793 3.43074 16.7707 3.65412 16.3686 3.96685L7.83568 11.651L7.52296 16.3419C8.01439 16.3419 8.19309 16.1632 8.46114 15.8951L10.6502 13.7507L15.2518 17.146C16.1006 17.6375 16.726 17.3694 16.9494 16.3866L19.9427 2.13516Z'
      fill='white'
    />
  </svg>
);

export default PlaneSvg;
