import ico1 from '../../assets/img/special/ico01.svg';
import ico2 from '../../assets/img/special/ico02.svg';
import ico3 from '../../assets/img/special/ico03.svg';

export const cardsList = [
  {
    ico: ico1,
    text: 'special card 1 text',
  },
  {
    ico: ico2,
    text: 'special card 2 text',
  },
  {
    ico: ico3,
    text: 'special card 3 text',
  },
];
