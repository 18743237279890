import img1 from '../../assets/img/benefits/ico01.svg';
import img2 from '../../assets/img/benefits/ico02.svg';
import img3 from '../../assets/img/benefits/ico03.svg';

export const Cards = [
  {
    img: img1,
    text: 'benefits card 1 text',
  },
  { img: img2, text: 'benefits card 2 text' },
  {
    img: img3,
    text: 'benefits card 3 text',
  },
];
